<template>
  <v-container class="error-page fill-height" tag="section">
    <v-row class="text-center" justify="center">
      <v-col cols="auto">
        <div class="display-3 mb-5 mt-10" style="font-size: 25px">
          {{ $store.state.app.errorMessage || 'Forbidden'}}
        </div>
        <v-btn @click="toHome()">Quay lại trạng thái trước</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Forbidden",
  methods: {
    toHome() {
      location.href = '/'
    }
  }
};
</script>

